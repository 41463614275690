<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <div style="max-width:300px">
        <v-text-field v-model="search" outlined rounded solo dense hide-details label="Search" append-icon="mdi-magnify"></v-text-field>
      </div>
      <v-btn icon rounded color="primary" small @click="getData(true)">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table dense :headers="headers" :items="dataitems" :search="search" class="elevation-1">
      <template v-slot:[`item.ticket_no`]="{ item }">
        <a href="javascript:;" @click="getDetail(item.ticket_no)">{{ item.ticket_no }}</a>
      </template>
      <template v-slot:[`item.subjects`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 150px;">{{ item.subjects }}</span>
          </template>
          <span>{{ item.subjects }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.priority`]="{ item }">
        <v-chip small v-if="item.priority=='HIGH'" href="javascript:;" color="error">{{ item.priority }}</v-chip>
        <v-chip small v-if="item.priority=='MEDIUM'" href="javascript:;" color="success">{{ item.priority }}</v-chip>
        <v-chip small v-if="!['HIGH', 'MEDIUM'].includes(item.priority)" href="javascript:;" color="info">{{ item.priority }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "Ticket",

  props:{
    title: String,
    status: String,
  },

  data() {
    return {
      loading: true,
      options: {},
      headers: [
        { text: "No.", value: "ticket_no" },
        { text: "Subjects", value: "subjects" },
        { text: "Customer", value: "customer_name" },
        { text: "Create Date", value: "created_date" },
        { text: "Last Modified Date", value: "last_modified_date" },
        { text: "Priority", value: "priority" },
      ],
      totaldataitems: 0,
      dataitems: [],
      datadetail: {},
      search: '',
    };
  },

  methods: {
    async getData(refresh) {
      if(refresh) this.AxiosStorageRemove('GET', 'ticket/data')

      this.loading = true;
      var config = {
        params: {
          status: this.status
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("ticket/data", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.dataitems = resData.data.tickets;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
    getDetail(no_){
      this.redirect('Sales.Ticket.Detail', { no_ : no_} );
    }
  },

  mounted() {
    this.getData();
  },
};
</script>
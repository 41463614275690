var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading,"elevation":"0"}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('div',{staticStyle:{"max-width":"300px"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","solo":"","dense":"","hide-details":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{attrs:{"icon":"","rounded":"","color":"primary","small":""},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.dataitems,"search":_vm.search},scopedSlots:_vm._u([{key:"item.ticket_no",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getDetail(item.ticket_no)}}},[_vm._v(_vm._s(item.ticket_no))])]}},{key:"item.subjects",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},'span',attrs,false),on),[_vm._v(_vm._s(item.subjects))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.subjects))])])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority=='HIGH')?_c('v-chip',{attrs:{"small":"","href":"javascript:;","color":"error"}},[_vm._v(_vm._s(item.priority))]):_vm._e(),(item.priority=='MEDIUM')?_c('v-chip',{attrs:{"small":"","href":"javascript:;","color":"success"}},[_vm._v(_vm._s(item.priority))]):_vm._e(),(!['HIGH', 'MEDIUM'].includes(item.priority))?_c('v-chip',{attrs:{"small":"","href":"javascript:;","color":"info"}},[_vm._v(_vm._s(item.priority))]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }